import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import ArticleList from '../components/Article-list'

const LeaguePage = () => (
  <>
    <div style ={{position:"relative", minHeight:"100vh"}}>
      <Header />
      <ArticleList />
      <Footer />
    </div>
  </>
)


export default LeaguePage
